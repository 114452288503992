.ListOfComments {
  min-height: 180px;
  max-height: 600px;
  overflow-y: scroll;
}

.Comments {
  min-height: 200px !important;
}

.Comments .Comment {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Comments .Comment + .Comment {
  margin-top: 10px;
}

.Comments .Comment .CommentContainer {
  display: flex;
  position: relative;
}

.Comments .Comment .Content {
  width: 750px;
  padding: 12px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.Comments .Comment .Content .Text {
  width: 80%;
}

.Comments .Comment .Content .Text span {
  font-size: 11px;
  color: #666;
}

.Comments .Comment.Admin .Text span {
  color: #ddd;
}

.Comments .Comment .Content .By {
  width: 20%;
  font-size: 11px;
  text-align: right;
}

.Comments .Comment.User {
  align-items: flex-start;
}

.Comments .Comment.Admin {
  align-items: flex-end;
}

.Comments .Comment.Admin .Content {
  border-bottom-right-radius: 0;
  background-color: #4c7dfe;
  color: #fff;
}

.Comments .Comment.User .Content {
  border-bottom-left-radius: 0;
  background-color: #e5e5ea;
  color: #222;
}

.Comments .Comment .Actions {
  width: 25px;
  position: absolute;
  right: 0;
  top: 0px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 0;
  transition: all ease 0.3s;
}

.Comments .Comment .Content:hover + .Actions,
.Comments .Comment .Actions:hover {
  right: -20px;
}

.Comments .Comment .Actions div {
  display: inline-block;
  width: 17px !important;
  height: 17px !important;
}

.Comments .Comment .Actions div + div {
  margin-top: 10px;
}

.Comments .Comment .Actions svg {
  cursor: pointer;
}

.Comments .Comment .Actions .Edit svg {
  width: 13px !important;
  height: 13px !important;
}

.Comments .Comment .Actions .Delete svg {
  width: 17px !important;
  height: 17px !important;
}

.SendMessage {
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  display: flex;
}

.SendMessage input {
  width: calc(100% - 50px);
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f1f1f1;
  border-radius: 25px;
  color: #000;
}

.SendMessageBtn {
  width: 50px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: #4c7dfe;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.SendMessageBtn svg {
  width: 25px !important;
  height: 25px !important;
  margin-left: 5px;
  color: #fff;
}

.LoadMore {
  margin: 30px 0 20px;
  display: flex;
  justify-content: center;
}

.LoadMore div {
  padding: 15px 40px;
  cursor: pointer;
  background-color: bisque;
  color: #222;
  border-radius: 6px;
}
