.DetailsContainer h2 {
  margin-bottom: 30px;
  font-size: 16px;
  text-transform: uppercase;
}

.DetailOverview {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.DetailOverview .Detail {
  width: 100%;
  padding: 12px 0;
  display: flex;
}

.DetailOverview .Detail div:first-child {
  width: 20%;
  font-weight: 600;
}

.DetailOverview .Detail div:last-child {
  width: 80%;
}

.ContentHeader {
  margin-bottom: 20px;
  font-size: 16px;
}
