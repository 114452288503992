.TableActions {
  position: absolute;
  right: 15px;
  display: flex;
  align-items: center;
}

.DataTable thead tr th:nth-child(2),
.DataTable thead tr th:nth-child(3),
.DataTable tbody tr td:nth-child(2),
.DataTable tbody tr td:nth-child(3) {
  display: none;
}
