.Container {
    display: flex;
    align-items: stretch;
}

.PageContainer {
    width: 100%;
}

.OfferContent h2 {
    margin: 20px 0 30px;
    font-size: 18px;
}

.OfferContent button {
    margin-top: 50px;
}