.PageNavigation {
  padding: 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PageActions {
  padding-right: 10px;
}

.NavigationMenu ul {
  display: flex;
}

.NavigationMenu ul li {
  padding: 15px 40px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all ease 0.3s;
  font-weight: 600;
}

.NavigationMenu ul li:hover {
  background-color: #fbcddb;
}

.NavigationMenu ul li.Active {
  background-color: #ee3d73;
  color: #fff;
}

.Header {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.TableActions {
  display: flex;
}

.DateRangeFilter {
  display: flex;
  align-items: center;
}

.SelectedFilterDates {
  padding: 0 0px 0 20px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  background-color: #ffcc00;
  border-radius: 22px;
  color: #444;
}

.SelectedFilterDates img {
  margin-right: -2px;
}

.SelectedFilterDates .Date {
  margin-right: 15px;
}

.SubmitDatesBtn {
  margin-top: 15px;
  text-align: right;
}

.DateRangeFilter .CalendarIcon {
  cursor: pointer;
}
