.Offers .Offer {
  padding: 15px;
  border: 1px solid #dedede;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  transition: all ease 0.3s;
}

.Offers .Offer + .Offer {
  margin-top: 20px;
}

.Offers .Offer:hover {
  background-color: #EFF6FA;
}

.Offers .Offer .Content {
  width: 60%;
}

.Offers .Offer p {
  margin-bottom: 0;
}

.LoadMore {
  margin: 30px 0 20px;
  display: flex;
  justify-content: center;
}

.LoadMore div {
  padding: 15px 40px;
  cursor: pointer;
  background-color: bisque;
  color: #222;
  border-radius: 6px;
}
