.FormControl {
  width: 100%;
  padding: 0 10px;
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: #fff;
  font-weight: 500;
  resize: vertical;
}

.FormControl:focus,
.FormControl:active,
.FormControl:hover {
  outline: none;
  box-shadow: none;
}

.FormControl:hover {
  border-color: hsl(0, 0%, 60%);
}

.FormControl:focus,
.FormControl:active {
  border-color: #2684ff;
}
