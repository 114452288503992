.Export {
  position: relative;
}

.ExportBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.ExportBtn svg {
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
}

.DropdownMenu {
  width: 150px;
  margin-top: 8px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
  position: absolute;
  right: 0;
  border-radius: 5px;
  transition: all ease 0.3s;
  opacity: 0;
  z-index: -1;
}

.ExportBtn:hover + .DropdownMenu,
.DropdownMenu:hover {
  opacity: 1;
  z-index: 9;
}

.DropdownMenu div {
  padding: 10px;
  cursor: pointer;
  transition: all ease 0.3s;
  display: flex;
  align-items: center;
}

.DropdownMenu div + div {
  border-top: 1px solid #ddd;
}

.DropdownMenu div:hover {
  background-color: #f8f8f8;
}

.DropdownMenu svg {
  width: 16px !important;
  height: 16px !important;
  margin-right: 5px;
}

.DropdownMenu .Excel svg {
  color: #1d6c41;
}

.DropdownMenu .Pdf svg {
  color: #ea4444;
}
