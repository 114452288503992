.PageNavigation {
  padding: 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PageActions {
  padding-right: 10px;
}

.Export {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.Export svg {
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
}

.NavigationMenu ul {
  display: flex;
}

.NavigationMenu ul li {
  padding: 15px 40px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all ease 0.3s;
  font-weight: 600;
}

.NavigationMenu ul li:hover {
  background-color: #fbcddb;
}

.NavigationMenu ul li.Active {
  background-color: #ee3d73;
  color: #fff;
}
