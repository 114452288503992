.Notes {
  margin-top: 14px;
  text-align: center;
}

.Notes span {
  text-transform: uppercase;
}

.Notes .Resend {
  margin-left: 5px;
  color: #35baf6;
  cursor: pointer;
}

.Notes .ResendWait {
  margin-left: 5px;
  color: #888;
  cursor: default;
}

.OtpInput input {
  width: 50px !important;
  height: 40px;
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: #fff;
  font-weight: 500;
}

.OtpInput input:focus,
.OtpInput input:active,
.OtpInput input:hover {
  outline: none;
}

.OtpInput input + input {
  margin-left: 20px;
}

@media (max-width: 768px) {
  .OtpInput input {
    width: 40px !important;
  }
}
