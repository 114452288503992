.StatsContainer,
.TicketsContainer {
  min-height: auto !important;
}

.Stats {
  display: flex;
}

.Stats .Stat {
  width: 33.33%;
  padding: 10px;
}

.Stats .Stat + .Stat {
  border-left: 1px solid #ddd;
}

.Stats .Stat div:first-child {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
}

.Stats .Stat div:last-child {
  color: #666;
}

.RaiseTicket svg{
  width: 1.5em !important;
  height: 1.5em !important;
  cursor: pointer;
}

.SupportCall > div {
  cursor: pointer;
}

.SupportCall svg {
  width: 1.7em !important;
  height: 1.7em !important;
  cursor: pointer;
}

@media (max-width: 768px) {
  .Stats {
    flex-direction: column;
  }

  .Stats .Stat {
    width: 100%;
    padding: 20px 10px;
    border-left: 0 !important;
  }

  .Stats .Stat + .Stat {
    border-top: 1px solid #ddd;
  }

  .Stats .Stat:first-child {
    padding-top: 5px;
  }

  .Stats .Stat:last-child {
    padding-bottom: 5px;
  }

  .Stats .Stat div:first-child {
    font-size: 14px;
  }
}
