.NavItem {
  display: flex;
  align-items: center;
}

.NavItem a {
  width: 100%;
  height: 46px;
  padding: 0 10px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 2px;
  color: #6a7782;
  position: relative;
  display: flex;
  align-items: center;
  border-top: 1px solid #f5f8fa;
  border-left: 4px solid #fff;
  transition: all ease 0.3s;
}

.NavItem:last-child a {
  border-bottom: 1px solid #f5f8fa;
}

.NavItem a:hover {
  border-left: 4px solid #004bca;
}

.NavItem a.active {
  border-left: 4px solid #004bca;
  background-color: #86b3ff;
  color: #fff;

}

.NavItem a svg {
  width: 1.5em !important;
  height: 1.5em !important;
  margin-right: 10px;
}
