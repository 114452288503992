.Wrapper {
  width: 100%;
  height: 100vh;
  padding-top: 40px;
  background-color: #f7f7f7;
}

.Logo {
  margin-top: 150px;
  display: flex;
  justify-content: center;
}

.Logo img {
  width: 300px;
}

.Container {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 768px) {
  .Logo {
    margin-top: 0;
  }
}
