h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: 600;
}

h1 {
  font-size: 18px;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #3498db;
}

/* Custom Classes */
.Pad15 {
  padding: 15px;
}

/* PageHeader */
.PageHeader {
  height: 50px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1) !important;
}

.PageHeader .ContentRight {
  display: flex;
}

.PageHeader .ContentRight button {
  margin-left: 8px;
}

/* Page Content */
.PageContent {
  width: 1280px;
  padding: 15px;
  margin: 40px auto;
  border-radius: 4px;
  background-color: #fff;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
}

@media (max-width: 768px) {
  .PageContent {
    min-height: 100vh !important;
    width: calc(100% - 20px);
    margin: 10px;
  }

  .PageContent.Type1 {
    min-height: auto !important;
  }
}

/* Page Navigations */
.PageNavigation {
  padding: 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PageActions {
  padding-right: 10px;
  position: relative;
}

.NavigationMenu ul {
  display: flex;
}

.NavigationMenu ul li {
  padding: 15px 40px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all ease 0.3s;
  font-weight: 600;
}

.NavigationMenu ul li:hover {
  background-color: #fbcddb;
}

.NavigationMenu ul li.Active {
  background-color: #ee3d73;
  color: #fff;
}

@media (max-width: 768px) {
  .PageNavigation {
    min-height: auto !important;
  }

  .NavigationMenu ul li {
    padding: 15px 20px;
  }
}

/* Content Header */
.ContentHeader {
  height: 80px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f1f1f1;
}

.ContentHeader .Title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
}

/* Main Content */
.MainContent {
  padding: 10px 24px 20px 24px;
}

/* Data Form */
.DataForm {
  padding: 0;
}

/* Form */
.FormContainer {
  margin: 0 -12px;
}

.FormContainer .FormBody {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}

.FormContainer .FormGroup {
  width: 33.33%;
  min-height: 110px;
  padding: 10px 12px;
  position: relative;
}

.FormContainer .FormActions {
  width: 33.33%;
  padding: 0 12px;
  display: flex;
}

.FormContainer .FormActions button {
  width: 50%;
}

.FormContainer .FormActions button + button {
  margin-left: 8px;
}

.FormContainer.ModalForm .FormGroup {
  width: 100%;
}

.FormContainer.ModalForm {
  padding: 10px 0;
}

.FormContainer.ModalForm .FormActions {
  width: 100%;
}

.FormContainer.ModalForm .FormActions button {
  width: 100%;
}

@media (max-width: 768px) {
  .FormContainer .FormGroup,
  .FormContainer .FormActions {
    width: 100%;
  }
}

.Checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Checkbox .ControlLabel {
  margin: 0;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
}

.Checkbox input {
  margin-right: 10px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.Checkbox .Checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  border: 1px solid #5dc900;
  background-color: transparent;
}

.Checkbox input:checked ~ .Checkmark {
  background-color: #5dc900;
}

.Checkbox .Checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.Checkbox input:checked ~ .Checkmark:after {
  display: block;
}

.Checkbox .Checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Table */
table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  padding: 5px;
  text-align: left;
}

table th {
  padding: 15px 5px;
  text-transform: uppercase;
  color: #222;
  font-size: 12px;
  font-weight: 600;
}

table th span {
  width: 20px;
  display: inline-block;
}

table thead,
table tbody tr {
  border-bottom: 1px solid #e6e6e6;
}

table.type-1 tbody tr:first-child {
  border-top: 1px solid #e6e6e6;
}

table tbody {
  color: #444;
}

table tbody tr {
  height: 43px;
  transition: background-color ease 0.3s;
}

table tbody tr:hover {
  background-color: #fafafa;
}

table tbody tr button {
  margin-right: 10px;
  height: 30px !important;
  font-size: 12px !important;
}

.ScrollableTable {
  overflow-x: scroll;
}

.ScrollableTable table {
  width: 1250px;
}

/* Data Table */
.DataTable.FixedWidth table tr td {
  display: flex !important;
  align-items: center;
}

.DataTable table tbody tr:first-child {
  border-bottom: 1px solid #e6e6e6;
}

.DataTable table tbody tr:last-child {
  border: 0;
}

/* Table Header */
.TableHeader {
  width: 100%;
  height: 43px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SearchTable {
  width: 300px;
}

@media (max-width: 768px) {
  .SearchTable {
    width: 100%;
  }
}

.TableActions ul {
  display: flex;
}

.TableActions ul li + li {
  margin-left: 8px;
}

/* Table Pagination */
.TableFooter {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #777;
  border-top: 1px solid #f7f7f7;
}

.TableRecordsCount {
  display: flex;
  align-items: center;
}

.PageSize {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.PageSize .FormControl {
  width: 90px;
  height: 30px;
  padding: 0 10px;
  margin-left: 10px;
  color: #777;
}

.TablePagination {
  display: flex;
  align-items: center;
}

.TablePagination .PageCountStat {
  margin-right: 10px;
}

.TablePagination .PageCountStat span {
  margin-left: 10px;
}

.TablePagination .GoToPage {
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.TablePagination .GoToPage .FormControl {
  width: 70px;
  height: 30px;
  padding: 0 10px;
  margin-left: 10px;
  color: #777;
}

.PaginationNav button {
  width: 30px;
  height: 30px;
  margin: 0 2px;
}

.FormControl {
  width: 100%;
  height: 40px;
  padding-left: 20px;
  border-radius: 4px;
  border: 0;
  background-color: rgba(255, 255, 255, 0.16);
  color: #fff;
}

.FormControl:focus,
.FormControl:active,
.FormControl:hover {
  outline: none;
}

/* Calendar */
@media (max-width: 768px) {
  .rdrDefinedRangesWrapper {
    display: none;
  }
}

/* Modal */
@media (max-width: 768px) {
  .CalendarModal {
    width: 400px !important;
  }
}
