.Header {
  height: 75px;
  padding: 0 20px;
  background-color: #2979ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ContentRight {
  display: flex;
  align-items: center;
}

.NavBtn svg {
  width: 2em !important;
  height: 2em !important;
  color: #fff;
}

.Logo {
  width: 180px;
  padding: 19px 10px;
}

.Logo img {
  width: 100%;
}

.Actions ul {
  display: flex;
  color: #fff;
}

.Actions ul li {
  height: 37px;
  display: flex;
  align-items: center;
}

.Actions ul li img {
  height: 37px;
}

.Actions ul li + li {
  margin-left: 8px;
}

.Profile svg {
  margin-right: 8px;
  width: 1.5em !important;
  height: 1.5em !important;
}

.Logout {
  margin-left: 20px !important;
  cursor: pointer;
}

.Logout svg {
  width: 1.7em !important;
  height: 1.7em !important;
  color: #fff;
}

.HappyBirthdayMsg {
  color: #fff;
  font-size: 18px;
}

@media (min-width: 768px) {
  .NavBtn,
  .Logo {
    display: none;
  }
}
