.DetailOverview {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.DetailOverview .Detail {
  width: 100%;
  padding: 12px 0;
  display: flex;
}

.DetailOverview .Detail div:first-child {
  width: 10%;
  font-weight: 600;
}

.DetailOverview .Detail div:last-child {
  width: 90%;
}

.Replies {
  padding: 15px 0;
}

.Replies h2 {
  padding-left: 15px;
  margin-bottom: 20px;
  font-size: 16px;
}

.ListOfReplies {
  padding: 15px;
  min-height: 180px;
  max-height: 600px;
  overflow-y: scroll;
}

.Replies .Reply {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Replies .Reply + .Reply {
  margin-top: 10px;
}

.Replies .Reply .Content {
  width: 750px;
  padding: 12px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
}

.Replies .Reply .Content div:first-child {
  width: 80%;
}

.Replies .Reply .Content div:last-child {
  width: 20%;
  font-size: 11px;
  text-align: right;
}

.Replies .Reply.User {
  align-items: flex-end;
}

.Replies .Reply.Support {
  align-items: flex-start;
}

.Replies .Reply.User .Content {
  border-bottom-right-radius: 0;
  background-color: #4c7dfe;
  color: #fff;
}

.Replies .Reply.Support .Content {
  border-bottom-left-radius: 0;
  background-color: #e5e5ea;
  color: #222;
}

.SendMessage {
  width: 100%;
  height: 50px;
  padding: 0 15px;
  margin-top: 10px;
  display: flex;
}

.SendMessage input {
  width: calc(100% - 50px);
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f1f1f1;
  border-radius: 25px;
  color: #000;
}

.SendMessageBtn {
  width: 50px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: #4c7dfe;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.SendMessageBtn svg {
  width: 25px !important;
  height: 25px !important;
  margin-left: 5px;
  color: #fff;
}

.LoadMore {
  margin: 30px 0 20px;
  display: flex;
  justify-content: center;
}

.LoadMore div {
  padding: 15px 40px;
  cursor: pointer;
  background-color: bisque;
  color: #222;
  border-radius: 6px;
}

@media (max-width: 768px) {
  .DetailOverview .Detail {
    flex-direction: column;
  }

  .DetailOverview .Detail div:first-child,
  .DetailOverview .Detail div:last-child {
    width: 100%;
  }

  .DetailOverview .Detail div:last-child {
    margin-top: 5px;
  }

  .TicketOverview,
  .Replies {
    min-height: auto !important;
  }

  .Replies .Reply .Content {
    width: 90%;
    flex-direction: column;
  }

  .Replies .Reply .Content div:first-child,
  .Replies .Reply .Content div:last-child {
    width: 100%;
  }

  .Replies .Reply .Content div:last-child {
    margin-top: 10px;
    text-align: left;
  }
}
