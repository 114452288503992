.MainNav {
  width: 250px;
  min-height: 100vh;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  z-index: 9;
}

.Logo {
  width: 180px;
  padding: 19px 10px;
}

.Logo img {
  width: 100%;
}

.MainNav .ProfileName {
  padding: 20px 10px;
  background-color: #eff6fa;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MainNav .ProfileIcon svg {
  width: 5em !important;
  height: 5em !important;
  color: #6a7782;
  opacity: 0.3;
}

.MainNav .Name {
  margin-top: 5px;
  font-size: 16px;
}

.MainNav .UserId {
  margin-top: 5px;
  font-size: 12px;
  color: #999;
}

.BackDrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
  .MainNav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: transform 0.3s ease-out;
  }

  .Open {
    transform: translateX(0);
  }

  .Close {
    transform: translateX(-100%);
  }
}
