.FormContainer {
  width: 480px;
  padding: 30px 40px !important;
  background-color: #fff;
}

.OnSuccess {
  padding: 0 12px;
  margin-bottom: 20px;
}

.OnSuccess h1 {
  font-size: 14px;
  font-weight: 500;
}

.ForgotPassword {
  padding: 0 12px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
