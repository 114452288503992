* {
  box-sizing: border-box;
}

body {
  font-family: "Quicksand", sans-serif;
  color: #222;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#F3F5F8;
}
